import { AnimatePresence } from 'framer-motion';
import React, {lazy, Suspense} from 'react';
import './css/App.css';
import {Provider} from "react-redux";
import store from "../src/reducers/store";
import ScrollToTop from "./components/ScrollToTop";
import AppHeader from "./components/shared/AppHeader";
import AppFooter from "./components/shared/AppFooter";
import {HomePage} from "./features/home/components/HomePage";
import { Route, BrowserRouter as Router, Routes} from "react-router-dom";

const AboutMe = lazy(() => import('./features/about_me/components/AboutMe'));
const Projects = lazy(() => import('./features/projects/components/Projects'))
function App() {
    return (
        <Provider store={store}>
            <AnimatePresence>
                <div className=" bg-secondary-light dark:bg-primary-dark transition duration-300">
                    <Router location={"/"}>
                        <ScrollToTop />
                        <AppHeader />
                        <Suspense fallback={""}>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="about" element={<AboutMe />} />
                                <Route path="projects" element={<Projects/>}/>
                            </Routes>
                        </Suspense>
                        <AppFooter />
                    </Router>
                </div>
            </AnimatePresence>
        </Provider>
    )
}

export  default App

