import {techData, techHeading, techHeading_esp} from "../data/TechData";
import {TECH_LOAD_DATA, TECH_LOAD_DATA_DEFAULT} from "../types/types";


const initialState = {
    technologies_list: techData,
    technologies_header: '',
}



const getInfoLanguageDefault = () => {
    let language_storage = localStorage.getItem("language")
    if (language_storage !== undefined && language_storage === "en") {
        return techHeading
    }
    else {
        return techHeading_esp
    }
}

const getInfoLanguage = (language_storage) => {
    if (language_storage !== undefined && language_storage === "es") {
        return techHeading
    }
    else {
        return techHeading_esp
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case TECH_LOAD_DATA_DEFAULT:
            return {
                ...state,
                technologies_header: getInfoLanguageDefault()
            }
        case TECH_LOAD_DATA:
            return {
                ...state,
                technologies_header: getInfoLanguage(action.payload)
            }
        default: return {...state}
    }
}


