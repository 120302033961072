import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger';

import rootReducer from './index'

const initialState = {

}

const middleware = [thunk]

const loggerMiddleware = createLogger();

const store = createStore(rootReducer, 
                          initialState, 
                          composeWithDevTools(applyMiddleware(...middleware, loggerMiddleware)),
                          )

export default store;