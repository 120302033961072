// Import images
import Java from '../images/tech_images/java.webp'
import React from '../images/tech_images/react.png'
import Docker from '../images/tech_images/docker.png'
import Aws from '../images/tech_images/aws.png'
import Spring from '../images/tech_images/spring.png'
import JavaScript from '../images/tech_images/javascript.png'
import TypeScript from '../images/tech_images/typescript.png'
import Redux from '../images/tech_images/redux.png'
import WebRTC from '../images/tech_images/webrtc.png'
import Angular from '../images/tech_images/angular.png'
import Php from '../images/tech_images/php.png'
import Csharp from '../images/tech_images/csharp.png'
import Tomcat from '../images/tech_images/tomcat.png'
import Nginx from '../images/tech_images/nginx.png'
import Apache from '../images/tech_images/apache.png'
import NetCore from '../images/tech_images/netcore.png'
import UCore from '../images/tech_images/ubuntucore.png'
import Mysql from '../images/tech_images/mysql.png'
import Postgre from '../images/tech_images/postgre.png'
import MSsql from '../images/tech_images/mssql.svg'
import SQLite from '../images/tech_images/sqlite.png'
import Android from '../images/tech_images/android.png'
import Material from '../images/tech_images/materialui.png'
import Bootstrap from '../images/tech_images/bootstrap.png'


export const techHeading = 'Some of the technologies with I\'ve been working for the last 5 years.';
export const techHeading_esp = 'Estas son algunas de las tecnologías con las que he trabajado los últimos 5 años.';

export const techData = [
	{
		id: 1,
		title: 'Java',
		img: Java,
	},
	{
		id: 2,
		title: 'React',
		img: React,
	},
	{
		id: 2,
		title: 'Android',
		img: Android,
	},
	{
		id: 3,
		title: 'Docker',
		img: Docker,
	},
	{
		id: 4,
		title: 'AWS',
		img: Aws,
	},
	{
		id: 5,
		title: 'Spring Boot',
		img: Spring,
	},
	{
		id: 6,
		title: 'JavaScript',
		img: JavaScript,
	},
	{
		id: 7,
		title: 'TypeScrypt',
		img: TypeScript,
	},
	{
		id: 9,
		title: 'Redux',
		img: Redux,
	},
	{
		id: 10,
		title: 'WebRTC',
		img: WebRTC,
	},
	{
		id: 11,
		title: 'Material',
		img: Material,
	},
	{
		id: 12,
		title: 'Bootstrap',
		img: Bootstrap,
	},
	{
		id: 11,
		title: 'Angular',
		img: Angular,
	},
	{
		id: 12,
		title: 'PHP',
		img: Php,
	},
	{
		id: 13,
		title: 'C#',
		img: Csharp,
	},
	{
		id: 14,
		title: 'Tomcat',
		img: Tomcat,
	},
	{
		id: 15,
		title: 'Nginx',
		img: Nginx,
	},
	{
		id: 16,
		title: 'Apache',
		img: Apache,
	},
	{
		id: 17,
		title: 'NetCore',
		img: NetCore,
	},
	{
		id: 18,
		title: 'Ubuntu Core',
		img: UCore,
	},
	{
		id: 19,
		title: 'MySql',
		img: Mysql,
	},
	{
		id: 20,
		title: 'MSSQL',
		img: MSsql,
	},
	{
		id: 20,
		title: 'PostgreSQL',
		img: Postgre,
	},
	{
		id: 21,
		title: 'SQLite',
		img: SQLite,
	},

];
