import {useEffect, useState} from "react";


const useLanguageSwitcher = () => {
    const[language, setLanguage] = useState(localStorage.language)
    const activeLanguage = language === 'en' ? 'es' : 'en'

    useEffect(() => {
        let exist = localStorage.getItem("language")
        const root = window.document.documentElement;
        if (exist === undefined) {
            root.classList.add("es");
            localStorage.setItem('language', "es");
        }
        else {
            root.classList.remove(activeLanguage);
            root.classList.add(language);
            localStorage.setItem('language', language);
        }
    })
    return [activeLanguage, setLanguage]
}

export default useLanguageSwitcher