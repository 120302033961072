import {HANDLE_CHANGE_LANGUAGE} from "../types/types";


const initialState = {
    language: localStorage.getItem("language"),
    app_header: [],
    app_home_info : [],
    app_about_me_info:[],
    app_about_me_bio:[],
}

const getDefaultAppHeader = () => {
    let language_storage = localStorage.getItem("language")
    if (language_storage !== undefined && language_storage === "es") {
        return ['Proyectos','Acerca de mí','Contacto', 'Contactame', 'Inicio']
    }
    else {
        return ['Projects','About Me','Contact', 'Hire Me', 'Home']
    }
}

const aboutMeDataEn = [
    {
        id: 1,
        bio: 'Hello, I\'m Miguel Diaz, a Software Engineer and DevOps with over 8 years of experience in software development.',
    },
    {
        id: 2,
        bio: 'I am proficient in various technologies, including Docker, React, Java Spring Boot, Android, WebRTC, and Linux. I am passionate about programming, software architecture, and open source. Currently, I am focusing on personal projects and studying AI for automation, cloud computing with AWS, and blockchain development (Web3). In my free time, I enjoy hiking, biking, and cooking.',
    },
    {
        id: 3,
        bio: 'My personal mission is to maintain a constant and meaningful communication with my family, actively participating in recreational activities and conversations. I want to be a loyal friend, listening and sharing genuine experiences. I aspire to be an attentive learner, an inspiring mentor, and a supportive coworker. Additionally, I am committed to being a compassionate person, helping those in need through charitable works and providing support to those who need it most.',
    },
];

const aboutMeDataEs = [
    {
        id: 1,
        bio: 'Hola, mi nombre es Miguel Diaz, soy un desarrollador full-stack y devops con más de 8 años de experiencia en la creación de software personalizado. Además de estas habilidades, también tengo experiencia en otras áreas.',
    },
    {
        id: 2,
        bio: 'A lo largo de mi trayectoria profesional, he adquirido experiencia en diversas tecnologías, tales como Docker, React, Java Spring Boot, Android, WebRTC, Linux, entre otros sistemas y lenguajes de programación. Soy un apasionado de los lenguajes de programación y la tecnología. Fuera del ámbito laboral, disfruto de actividades como el senderismo, el ciclismo y la cocina. Actualmente, dedico mi tiempo a proyectos personales y al estudio de inteligencia artificial para automatización, computación en la nube con AWS y desarrollo de blockchain (Web3).',
    },
    {
        id: 3,
        bio: 'Mi misión personal es mantener una comunicación constante y significativa con mi familia, participando activamente en actividades recreativas y conversaciones. Quiero ser un amigo leal, escuchando y compartiendo experiencias genuinas. Aspiro a ser un aprendiz atento, un mentor inspirador y un compañero de trabajo solidario. Además, me comprometo a ser una persona compasiva, ayudando a los necesitados a través de obras de caridad y brindando apoyo a quienes más lo requieren.',
    },
];

const getDefaultInfoHome = () => {
    let language_storage = localStorage.getItem("language")
    if (language_storage !== undefined && language_storage === "es") {
        return [ 'Protafolio de Proyectos'
                ,'Buscar proyectos por titulo o filtrar por categoría'
                ,'Buscar Proyecto'
                , 'Todos'
                , 'Sigueme'
                , 'Proyectos'
                , 'Hola, Yo soy Miguel'
                , 'Ingeniero Desarrollador de Software & Devops'
                , 'Descargar CV']
    }
    else {
        return ['Projects portfolio'
            ,'Search projects by title or filter by category'
            ,'Projects'
            , 'All Projects'
            , 'Follow me'
            , 'More Projects'
            , 'HI, IAM MIGUEL'
            , 'Software Engineer & Devops'
            , 'Download CV']
    }
}

const getInfoAppHeader = (lang) => {
    if (lang !== undefined && lang === "en") {
        return ['Proyectos','Acerca de mí','Contacto', 'Contactame', 'Inicio']
    }
    else {
        return ['Projects','About Me','Contact', 'Hire Me', 'Home']
    }
}

const getInfoHome = (lang) => {
    if (lang !== undefined && lang === "en") {
        return [ 'Protafolio de Proyectos'
            ,'Buscar proyectos por titulo o filtrar por categoría'
            ,'Buscar Proyecto'
            , 'Todos'
            , 'Sigueme'
            , 'Proyectos'
            , 'Hola, Yo soy Miguel'
            , 'Ingeniero Desarrollador de Software & Devops'
            , 'Descargar CV']
    }
    else {
        return ['Projects portfolio'
            ,'Search projects by title or filter by category'
            ,'Search Projects'
            , 'All Projects'
            , 'Follow me'
            , 'Projects'
            , 'HI, IAM MIGUEL'
            , 'Software Engineer & Devops'
            , 'Download CV']
    }
}


const getInfoAboutMe = (lang) => {
    if (lang !== undefined && lang === "en") {
        return ['Años de expieriencia'
            , 'Tareas terminadas'
            , 'Comentarios positivos'
            , 'Proyectos terminados'
            , 'Algunas de las empresas que han confiado en mi']
    }
    else {
        return ['Years of experience\n'
            , 'Completed Tasks'
            , 'Positive feedback'
            , 'Projects completed'
            , 'Some of the brands that trust me\n']
    }
}

const getDefaultInfoAboutMe = () => {
    let language_storage = localStorage.getItem("language")
    if (language_storage !== undefined && language_storage === "es") {
        return ['Años de expieriencia'
            , 'Tareas terminadas'
            , 'Comentarios positivos'
            , 'Proyectos terminados'
            , 'Algunas de las empresas que han confiado en mi']
    }
    else {
        return ['Years of experience\n'
            , 'Completed Tasks'
            , 'Positive feedback'
            , 'Projects completed'
            , 'Some of the brands that trust me\n']
    }
}

const getInfoAboutMeBio = (lang) => {
    if (lang !== undefined && lang === "en") {
        return aboutMeDataEs
    }
    else {
        return aboutMeDataEn
    }
}

const getDefaultInfoAboutMeBio = () => {
    let language_storage = localStorage.getItem("language")
    if (language_storage !== undefined && language_storage === "es") {
        return aboutMeDataEs
    }
    else {
        return aboutMeDataEn
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_CHANGE_LANGUAGE:
            state.app_header = getDefaultAppHeader()
            state.app_home_info = getDefaultInfoHome()
            state.app_about_me_bio = getDefaultInfoAboutMeBio()
            state.app_about_me_info = getDefaultInfoAboutMe()
            if (action.payload !== undefined) {
                state.app_header = getInfoAppHeader(action.payload)
                state.app_home_info = getInfoHome(action.payload)
                state.app_about_me_bio = getInfoAboutMeBio(action.payload)
                state.app_about_me_info = getInfoAboutMe(action.payload)
            }
            return {
                ...state,
                language: action.payload
            }
        default: return {...state}
    }
}


