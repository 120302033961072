import AppBanner from "../../../components/shared/AppBanner";

export const HomePage = () => {
    return (
        <>
            <div className={"container mx-auto"}>
                <AppBanner/>
            </div>
        </>
    )
}